<template>
    <div>
        <div v-if="loaded">
            <v-card flat style="background-color: var(--v-background-base) !important">
                <v-toolbar color="greyBase" dark :extended="!$vuetify.breakpoint.mobile" flat extension-height="55">
                    <v-toolbar-title>
                        <b class="mr-3">Notifications Hub</b>
                    </v-toolbar-title>
                </v-toolbar>

                <v-card class="mx-auto" :max-width="!$vuetify.breakpoint.mobile ? '94vw' : '100%'" :style="{
                    'margin-top': !$vuetify.breakpoint.mobile ? '-64px' : '0px',
                    'border-radius': !$vuetify.breakpoint.mobile ? '50px' : '0px',
                }">
                    <v-card-text :style="{
                        height: !$vuetify.breakpoint.mobile ? '90vh' : '90vh',
                        'overflow-y': 'auto',
                    }">
                        <v-row>
                            <v-col cols="12" sm="2" class="mx-0 pr-0">
                                <v-list class="mx-0 pr-0">
                                    <v-list-item @click="type = 'all'" :style="{'border-left': type == 'all' ?'4px solid #2196F3':''}">
                                        <v-list-item-action>
                                            <v-icon >group</v-icon>
                                        </v-list-item-action>
                                        <v-list-item-content>
                                            <v-list-item-title style="font-size: 14px">
                                                All Tasks
                                            </v-list-item-title>
                                        </v-list-item-content>
                                        <v-list-item-action>
                                        </v-list-item-action>
                                    </v-list-item>
                                    <v-list-item @click="type = 'user'" :style="{'border-left': type == 'user' ?'4px solid #2196F3':''}">
                                        <v-list-item-action>
                                            <v-icon >person</v-icon>
                                        </v-list-item-action>
                                        <v-list-item-content>
                                            <v-list-item-title style="font-size: 14px">
                                                My Tasks
                                            </v-list-item-title>
                                        </v-list-item-content>
                                        <v-list-item-action>
                                            <v-chip >{{ metrics.user }}</v-chip>
                                        </v-list-item-action>
                                    </v-list-item>
                                   
                                    <v-divider></v-divider>
                                    <v-subheader style="font-size: 16px"  v-if=" metrics.teams.length>0">
                                        <v-list-item>
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    Teams
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-subheader>
                                    <v-divider></v-divider>
                                        <v-list-item v-for="team in metrics.teams" :key="team.teamId"
                                            :style="{ 'border-left': '3px solid ' + getTeamColour(team.teamId), 'border-left': type == 'team' &&teamId==team.teamId ?'4px solid #2196F3':'' }" 
                                            @click="type = 'team', teamId = team.teamId"
                                            class="mt-1">
                                            <v-list-item-content wrap>
                                                <v-list-item-title style="font-size: 14px; text-transform: capitalize" class="primaryText--text">{{
                                                    getTeamName(team.teamId)
                                                }}</v-list-item-title>
                                            </v-list-item-content>
                                            <v-list-item-action>
                                                <v-chip>{{ team.count }}</v-chip>
                                            </v-list-item-action>
                                        </v-list-item>
                                    <v-divider></v-divider>
                                    <v-subheader style="font-size: 16px" v-if="availableCategories.length>0">
                                        <v-list-item>
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    Categories
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-subheader>
                                    <v-divider></v-divider>
                                    <v-list>
                                        <v-list-item v-for="(cat, index) in availableCategories" :key="index"
                                            @click="category = cat"
                                            :style="{ 'border-left': category == cat ? '4px solid var(--v-primary-base)' : '' }">
                                            <v-list-item-content>
                                                <v-list-item-title style="font-size: 14px" class="text-wrap">
                                                    {{ cat }}
                                                </v-list-item-title>
                                            </v-list-item-content>
                                            <v-list-item-action>
                                                <v-chip>{{ metrics[cat] }}</v-chip>
                                            </v-list-item-action>
                                        </v-list-item>
                                    </v-list>
                                </v-list>
                            </v-col>
                            <v-col cols="12" sm="10" class="mx-0 px-0" style="max-height: 100%; overflow-y: auto">
                                <Main :type="type" :category="category" :teamId="teamId" />
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-card>
        </div>
        <div v-else style="height: 90vh; padding-top: 0; margin-top: 0" class="text-center">
            <v-card flat color="transparent">
                <valhalla-loading />
            </v-card>
        </div>

    </div>
</template>

<script>
import Main from '../components/Notification/Main.vue'

export default {
    components: {
        Main
    },
    data: () => ({
        availableCategories: [],
        categories: [
            { value: 'all', name: 'All', icon: 'apps' },
            // {value: 'bookings', name: 'Bookings', icon: 'today'},
            { value: 'Consignee Profile', name: 'Consignee Profiles', icon: 'groups' },
            // {value: 'shipments', name: 'Shipments', icon: 'directions_boat'},
            { value: 'Task', name: 'Tasks', icon: 'format_list_numbered' },
        ],
        category: 'all',
        expandTeam: false,
        loaded: false,
        metrics: {},
        teams: [],
        teamId: null,
        type: 'all'
    }),
    computed: {
        sumTeamNotifications() {
            return this.metrics.teams ? this.metrics.teams.reduce((a, b) => a + b.count, 0) : 0
        },
    },
    created() {
        this.getTeams()
        this.getNotificationTotals()
    },
    methods: {
        categoryIcon(category) {
            let findIndex = this.categories.find(x => x.name == category)
            return findIndex ? findIndex.icon : 'apps'
        },
        async getTeams() {
            this.teams = await this.$API.getTeams()
        },
        getTeamColour(id) {
            let findIndex = this.teams.find(team => team.id == id)
            return findIndex ? findIndex.colour : 'grey'
        },
        getTeamName(id) {
            let findIndex = this.teams.find(team => team.id == id)
            return findIndex ? findIndex.name : null
        },
        async getNotificationTotals() {
            this.loaded = false
            this.metrics = await this.$API.getNotificationTotals()
            this.availableCategories = Object.keys(this.metrics).filter(x => x != 'user' && x != 'teams')
            this.category = this.availableCategories[0]
            this.loaded = true
        }
    }

}
</script>
